import { UseQueryOptions, useQueries } from 'react-query';

import { Export } from '@dynamic-labs/sdk-api';

import { exportsApi } from '../../../../../services/api';

type ExportOptions = UseQueryOptions<Export, unknown, Export, Array<string>>;

export const useFetchDetailsForExportsQuery = (
  environmentId: string,
  exportIds: string[],
  options?: ExportOptions,
) => {
  // do a useQuery for each exportId which is in pending state
  const queries = useQueries(
    exportIds.map((exportId) => ({
      enabled: options?.enabled,
      onSuccess: options?.onSuccess,
      queryFn: () => exportsApi.getExportById({ environmentId, exportId }),
      queryKey: ['exportId', exportId],
      refetchInterval: options?.refetchInterval,
      refetchOnWindowFocus: false,
    })),
  );

  // return data and refetch function for every query
  return {
    data: queries.map((query) => query.data),
    refetch: () => queries.forEach((query) => query.refetch()),
  };
};
