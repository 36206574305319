/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackupMultipleClientKeySharesRequest
 */
export interface BackupMultipleClientKeySharesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BackupMultipleClientKeySharesRequest
     */
    encryptedAccountCredentials: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BackupMultipleClientKeySharesRequest
     */
    passwordEncrypted: boolean;
}

export function BackupMultipleClientKeySharesRequestFromJSON(json: any): BackupMultipleClientKeySharesRequest {
    return BackupMultipleClientKeySharesRequestFromJSONTyped(json, false);
}

export function BackupMultipleClientKeySharesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackupMultipleClientKeySharesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'encryptedAccountCredentials': json['encryptedAccountCredentials'],
        'passwordEncrypted': json['passwordEncrypted'],
    };
}

export function BackupMultipleClientKeySharesRequestToJSON(value?: BackupMultipleClientKeySharesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'encryptedAccountCredentials': value.encryptedAccountCredentials,
        'passwordEncrypted': value.passwordEncrypted,
    };
}

