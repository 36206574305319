/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalWalletFundingDefaultSettings,
    ExternalWalletFundingDefaultSettingsFromJSON,
    ExternalWalletFundingDefaultSettingsFromJSONTyped,
    ExternalWalletFundingDefaultSettingsToJSON,
} from './ExternalWalletFundingDefaultSettings';

/**
 * 
 * @export
 * @interface FundingExternalWalletsDefaultSettings
 */
export interface FundingExternalWalletsDefaultSettings {
    /**
     * 
     * @type {Array<ExternalWalletFundingDefaultSettings>}
     * @memberof FundingExternalWalletsDefaultSettings
     */
    chainSettings?: Array<ExternalWalletFundingDefaultSettings>;
}

export function FundingExternalWalletsDefaultSettingsFromJSON(json: any): FundingExternalWalletsDefaultSettings {
    return FundingExternalWalletsDefaultSettingsFromJSONTyped(json, false);
}

export function FundingExternalWalletsDefaultSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundingExternalWalletsDefaultSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chainSettings': !exists(json, 'chainSettings') ? undefined : ((json['chainSettings'] as Array<any>).map(ExternalWalletFundingDefaultSettingsFromJSON)),
    };
}

export function FundingExternalWalletsDefaultSettingsToJSON(value?: FundingExternalWalletsDefaultSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chainSettings': value.chainSettings === undefined ? undefined : ((value.chainSettings as Array<any>).map(ExternalWalletFundingDefaultSettingsToJSON)),
    };
}

