/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalWalletFundingTokenRule {
    Recommended = 'recommended',
    Exact = 'exact'
}

export function ExternalWalletFundingTokenRuleFromJSON(json: any): ExternalWalletFundingTokenRule {
    return ExternalWalletFundingTokenRuleFromJSONTyped(json, false);
}

export function ExternalWalletFundingTokenRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalWalletFundingTokenRule {
    return json as ExternalWalletFundingTokenRule;
}

export function ExternalWalletFundingTokenRuleToJSON(value?: ExternalWalletFundingTokenRule | null): any {
    return value as any;
}

