/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalWalletFundingTokenRule,
    ExternalWalletFundingTokenRuleFromJSON,
    ExternalWalletFundingTokenRuleFromJSONTyped,
    ExternalWalletFundingTokenRuleToJSON,
} from './ExternalWalletFundingTokenRule';

/**
 * 
 * @export
 * @interface ExternalWalletFundingDefaultSettingsToken
 */
export interface ExternalWalletFundingDefaultSettingsToken {
    /**
     * 
     * @type {string}
     * @memberof ExternalWalletFundingDefaultSettingsToken
     */
    value?: string;
    /**
     * 
     * @type {ExternalWalletFundingTokenRule}
     * @memberof ExternalWalletFundingDefaultSettingsToken
     */
    rule?: ExternalWalletFundingTokenRule;
}

export function ExternalWalletFundingDefaultSettingsTokenFromJSON(json: any): ExternalWalletFundingDefaultSettingsToken {
    return ExternalWalletFundingDefaultSettingsTokenFromJSONTyped(json, false);
}

export function ExternalWalletFundingDefaultSettingsTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalWalletFundingDefaultSettingsToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'rule': !exists(json, 'rule') ? undefined : ExternalWalletFundingTokenRuleFromJSON(json['rule']),
    };
}

export function ExternalWalletFundingDefaultSettingsTokenToJSON(value?: ExternalWalletFundingDefaultSettingsToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'rule': ExternalWalletFundingTokenRuleToJSON(value.rule),
    };
}

