/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecoverMultipleClientKeySharesRequest
 */
export interface RecoverMultipleClientKeySharesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RecoverMultipleClientKeySharesRequest
     */
    keyShareIds?: Array<string>;
}

export function RecoverMultipleClientKeySharesRequestFromJSON(json: any): RecoverMultipleClientKeySharesRequest {
    return RecoverMultipleClientKeySharesRequestFromJSONTyped(json, false);
}

export function RecoverMultipleClientKeySharesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecoverMultipleClientKeySharesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keyShareIds': !exists(json, 'keyShareIds') ? undefined : json['keyShareIds'],
    };
}

export function RecoverMultipleClientKeySharesRequestToJSON(value?: RecoverMultipleClientKeySharesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyShareIds': value.keyShareIds,
    };
}

