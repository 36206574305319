/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetExposureSpenderToInfo
 */
export interface AssetExposureSpenderToInfo {
    /**
     * 
     * @type {string}
     * @memberof AssetExposureSpenderToInfo
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof AssetExposureSpenderToInfo
     */
    spenderAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetExposureSpenderToInfo
     */
    usdValue?: string;
}

export function AssetExposureSpenderToInfoFromJSON(json: any): AssetExposureSpenderToInfo {
    return AssetExposureSpenderToInfoFromJSONTyped(json, false);
}

export function AssetExposureSpenderToInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetExposureSpenderToInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'spenderAlias': !exists(json, 'spenderAlias') ? undefined : json['spenderAlias'],
        'usdValue': !exists(json, 'usdValue') ? undefined : json['usdValue'],
    };
}

export function AssetExposureSpenderToInfoToJSON(value?: AssetExposureSpenderToInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'spenderAlias': value.spenderAlias,
        'usdValue': value.usdValue,
    };
}

