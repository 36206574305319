import { createContext, useContext, useMemo, ReactNode } from 'react';

import { OriginResponse } from '@dynamic-labs/sdk-api';
import { useCors } from '@dynamic-labs/redcoast-query';

import { useEnvironmentsContext } from '../EnvironmentsContext';

export type OriginsContextValue = {
  addOrigin: (origin: string) => Promise<void>;
  deleteOrigin: (originId: string) => Promise<void>;
  isLoadingOrigins: boolean;
  originData: Array<OriginResponse> | undefined;
};

export const OriginsContext = createContext<OriginsContextValue | undefined>(
  undefined,
);

type OriginsContextProviderProps = {
  children: ReactNode;
};

export const OriginsContextProvider = ({
  children,
}: OriginsContextProviderProps) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const environmentId = activeEnvironment?.id;

  const { addOrigin, deleteOrigin, isLoading, origins } = useCors({
    environmentId: environmentId || '',
  });

  const value = useMemo(
    () => ({
      addOrigin,
      deleteOrigin,
      isLoadingOrigins: isLoading,
      originData: origins?.origins,
    }),
    [addOrigin, deleteOrigin, isLoading, origins],
  );

  return (
    <OriginsContext.Provider value={value}>{children}</OriginsContext.Provider>
  );
};

export const useOriginsContext = () => {
  const context = useContext(OriginsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useOriginsContext not wrapped in `OriginsContextProvider`.',
    );
  }

  return context;
};
