/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenRoomResponse
 */
export interface OpenRoomResponse {
    /**
     * 
     * @type {string}
     * @memberof OpenRoomResponse
     */
    roomId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenRoomResponse
     */
    serverKeygenIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpenRoomResponse
     */
    walletId?: string;
}

export function OpenRoomResponseFromJSON(json: any): OpenRoomResponse {
    return OpenRoomResponseFromJSONTyped(json, false);
}

export function OpenRoomResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenRoomResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomId': json['roomId'],
        'serverKeygenIds': !exists(json, 'serverKeygenIds') ? undefined : json['serverKeygenIds'],
        'walletId': !exists(json, 'walletId') ? undefined : json['walletId'],
    };
}

export function OpenRoomResponseToJSON(value?: OpenRoomResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomId': value.roomId,
        'serverKeygenIds': value.serverKeygenIds,
        'walletId': value.walletId,
    };
}

