import { useQuery } from 'react-query';

import { usersApi } from '../../../../services/api';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';

export const useUserDetails = (userId?: string) => {
  const { activeEnvironment } = useEnvironmentsContext();

  return useQuery(
    [userId, 'userDetails'],
    () =>
      usersApi
        .getUser({
          environmentId: activeEnvironment?.id as string,
          userId: userId as string,
        })
        .then(({ user }) => user),
    {
      enabled: !!userId && !!activeEnvironment,
    },
  );
};
