/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FundingExternalWalletsDefaultSettings,
    FundingExternalWalletsDefaultSettingsFromJSON,
    FundingExternalWalletsDefaultSettingsFromJSONTyped,
    FundingExternalWalletsDefaultSettingsToJSON,
} from './FundingExternalWalletsDefaultSettings';
import {
    FundingExternalWalletsMinAmount,
    FundingExternalWalletsMinAmountFromJSON,
    FundingExternalWalletsMinAmountFromJSONTyped,
    FundingExternalWalletsMinAmountToJSON,
} from './FundingExternalWalletsMinAmount';

/**
 * 
 * @export
 * @interface FundingExternalWallets
 */
export interface FundingExternalWallets {
    /**
     * 
     * @type {boolean}
     * @memberof FundingExternalWallets
     */
    enabled: boolean;
    /**
     * 
     * @type {FundingExternalWalletsMinAmount}
     * @memberof FundingExternalWallets
     */
    minAmount?: FundingExternalWalletsMinAmount;
    /**
     * 
     * @type {FundingExternalWalletsDefaultSettings}
     * @memberof FundingExternalWallets
     */
    defaultSettings?: FundingExternalWalletsDefaultSettings;
}

export function FundingExternalWalletsFromJSON(json: any): FundingExternalWallets {
    return FundingExternalWalletsFromJSONTyped(json, false);
}

export function FundingExternalWalletsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundingExternalWallets {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'minAmount': !exists(json, 'minAmount') ? undefined : FundingExternalWalletsMinAmountFromJSON(json['minAmount']),
        'defaultSettings': !exists(json, 'defaultSettings') ? undefined : FundingExternalWalletsDefaultSettingsFromJSON(json['defaultSettings']),
    };
}

export function FundingExternalWalletsToJSON(value?: FundingExternalWallets | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'minAmount': FundingExternalWalletsMinAmountToJSON(value.minAmount),
        'defaultSettings': FundingExternalWalletsDefaultSettingsToJSON(value.defaultSettings),
    };
}

