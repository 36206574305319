/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    ThresholdSignatureScheme,
    ThresholdSignatureSchemeFromJSON,
    ThresholdSignatureSchemeFromJSONTyped,
    ThresholdSignatureSchemeToJSON,
} from './ThresholdSignatureScheme';

/**
 * 
 * @export
 * @interface ImportWaasPrivateKeyRequest
 */
export interface ImportWaasPrivateKeyRequest {
    /**
     * 
     * @type {ChainEnum}
     * @memberof ImportWaasPrivateKeyRequest
     */
    chain: ChainEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportWaasPrivateKeyRequest
     */
    clientKeygenIds: Array<string>;
    /**
     * 
     * @type {ThresholdSignatureScheme}
     * @memberof ImportWaasPrivateKeyRequest
     */
    thresholdSignatureScheme: ThresholdSignatureScheme;
}

export function ImportWaasPrivateKeyRequestFromJSON(json: any): ImportWaasPrivateKeyRequest {
    return ImportWaasPrivateKeyRequestFromJSONTyped(json, false);
}

export function ImportWaasPrivateKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportWaasPrivateKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chain': ChainEnumFromJSON(json['chain']),
        'clientKeygenIds': json['clientKeygenIds'],
        'thresholdSignatureScheme': ThresholdSignatureSchemeFromJSON(json['thresholdSignatureScheme']),
    };
}

export function ImportWaasPrivateKeyRequestToJSON(value?: ImportWaasPrivateKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chain': ChainEnumToJSON(value.chain),
        'clientKeygenIds': value.clientKeygenIds,
        'thresholdSignatureScheme': ThresholdSignatureSchemeToJSON(value.thresholdSignatureScheme),
    };
}

