/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    ExternalWalletFundingDefaultSettingsToken,
    ExternalWalletFundingDefaultSettingsTokenFromJSON,
    ExternalWalletFundingDefaultSettingsTokenFromJSONTyped,
    ExternalWalletFundingDefaultSettingsTokenToJSON,
} from './ExternalWalletFundingDefaultSettingsToken';

/**
 * 
 * @export
 * @interface ExternalWalletFundingDefaultSettings
 */
export interface ExternalWalletFundingDefaultSettings {
    /**
     * 
     * @type {ChainEnum}
     * @memberof ExternalWalletFundingDefaultSettings
     */
    chain?: ChainEnum;
    /**
     * 
     * @type {ExternalWalletFundingDefaultSettingsToken}
     * @memberof ExternalWalletFundingDefaultSettings
     */
    token?: ExternalWalletFundingDefaultSettingsToken;
}

export function ExternalWalletFundingDefaultSettingsFromJSON(json: any): ExternalWalletFundingDefaultSettings {
    return ExternalWalletFundingDefaultSettingsFromJSONTyped(json, false);
}

export function ExternalWalletFundingDefaultSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalWalletFundingDefaultSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chain': !exists(json, 'chain') ? undefined : ChainEnumFromJSON(json['chain']),
        'token': !exists(json, 'token') ? undefined : ExternalWalletFundingDefaultSettingsTokenFromJSON(json['token']),
    };
}

export function ExternalWalletFundingDefaultSettingsToJSON(value?: ExternalWalletFundingDefaultSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chain': ChainEnumToJSON(value.chain),
        'token': ExternalWalletFundingDefaultSettingsTokenToJSON(value.token),
    };
}

