import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AlertProps, Section } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { useOriginsContext } from '../../../../../../app/context/OriginsContext';
import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { ROUTES } from '../../../../../../app/components/Navigation/data';

import styles from './SocialSection.module.scss';
import { SocialToggle } from './SocialToggle';

const socialProviderDocUrls: { [key in ProviderEnum]?: string } = {
  [ProviderEnum.Apple]: 'https://docs.dynamic.xyz/social-providers/apple',
  [ProviderEnum.Discord]: 'https://docs.dynamic.xyz/social-providers/discord',
  [ProviderEnum.Facebook]: 'https://docs.dynamic.xyz/social-providers/facebook',
  [ProviderEnum.Farcaster]:
    'https://docs.dynamic.xyz/social-providers/farcaster',
  [ProviderEnum.Github]: 'https://docs.dynamic.xyz/social-providers/github',
  [ProviderEnum.Google]: 'https://docs.dynamic.xyz/social-providers/google',
  [ProviderEnum.Telegram]: 'https://docs.dynamic.xyz/social-providers/telegram',
  [ProviderEnum.Twitch]: 'https://docs.dynamic.xyz/social-providers/twitch',
  [ProviderEnum.Twitter]: 'https://docs.dynamic.xyz/social-providers/twitter',
};

type SocialSectionProps = {
  disabled: boolean;
};

export const SocialSection: FC<SocialSectionProps> = ({ disabled }) => {
  const { t } = useTranslation();

  const { availableSocialProviders } = useProvidersContext();
  const { enableTelegram, enableTiktok } = useFlags();

  const useAvailableSocialProvider = availableSocialProviders.filter(
    (provider) => {
      if (!enableTelegram && provider === ProviderEnum.Telegram) return false;
      if (!enableTiktok && provider === ProviderEnum.Tiktok) return false;
      return true;
    },
  );

  const { originData } = useOriginsContext();
  const navigation = useNavigate();

  const missingCorsOrigins =
    originData === undefined || originData.length === 0;

  const missingCorsOriginsAlert: AlertProps = {
    action: {
      onClick: () => navigation(ROUTES.security),
      text: t(
        'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.action',
      ),
    },
    description: t(
      'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.description',
    ),
    title: t(
      'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.title',
    ),
    variant: 'warning',
  };

  return (
    <Section
      title={t('v2.page.log_in_methods.social.title')}
      description={t('v2.page.log_in_methods.social.description')}
      alerts={missingCorsOrigins ? [missingCorsOriginsAlert] : undefined}
      alertsAlignment='top'
    >
      <div className={styles.section}>
        {useAvailableSocialProvider.map((provider) => (
          <SocialToggle
            key={provider}
            provider={provider}
            docsUrl={socialProviderDocUrls[provider]}
            disabled={missingCorsOrigins || disabled}
          />
        ))}
      </div>
    </Section>
  );
};
