import { type FC, useMemo, useState } from 'react';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api-core';

import { useOnrampProvidersContext } from '../../../../../app/context/OnrampProvidersContext';
import { CoinbasePayIcon } from '../../../../../icons';

export const CoinbaseCard: FC = () => {
  const [loading, setLoading] = useState(false);
  const { providers, createProvider, toggleProvider } =
    useOnrampProvidersContext();

  const coinbaseProvider = useMemo(
    () =>
      providers.find(
        (provider) => provider.provider === ProviderEnum.CoinbaseOnramp,
      ),
    [providers],
  );

  const coinbaseToggleChecked = Boolean(coinbaseProvider?.enabledAt);

  const handleToggle = async () => {
    setLoading(true);

    if (!coinbaseProvider) {
      await createProvider(
        {
          provider: ProviderEnum.CoinbaseOnramp,
        },
        {
          onSettled: async (provider) => {
            if (provider) {
              await toggleProvider(provider, {
                onSettled: () => setLoading(false),
              });
            } else {
              setLoading(false);
            }
          },
        },
      );
      return;
    }

    await toggleProvider(coinbaseProvider, {
      onSettled: () => setLoading(false),
    });
  };

  return (
    <SingleToggleCard
      accordionKey='coinbase-enabled'
      title='Coinbase'
      allowExpand={false}
      data-testid='coinbase-toggle'
      Icon={<CoinbasePayIcon />}
      inputProps={{
        checked: coinbaseToggleChecked,
        disabled: loading,
        id: 'coinbase-enabled',
        isSaved: true,
        onChange: handleToggle,
        type: 'toggle',
      }}
    />
  );
};
