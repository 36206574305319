import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-namespace
import * as Tabs from '@radix-ui/react-tabs';

import {
  EnvironmentEnum,
  Session,
  User,
  WalletProviderEnum,
} from '@dynamic-labs/sdk-api';

import Portal from '../../../../components/Portal';
import CopyButton from '../../../../components/CopyButton';
import { CloseIcon, TrashIcon } from '../../../../../icons';
import { Typography } from '../../../../components/Typography';
import { useChainalysisContext } from '../../../../context/ChainalysisContext';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';

import { useUserDetails } from './useUserDetails';
import SessionsTable from './SessionsTable/sessions-table';
import styles from './user-details-modal.module.css';
import { ChainalysisChecksTable } from './ChainalysisChecks';
import { AccountTab } from './AccountTab';

type Props = {
  deleteUser: () => void;
  setUser: (data: User | undefined) => void;
  user: User;
};

const UserDetailsModal = ({ setUser, user, deleteUser }: Props) => {
  const { t } = useTranslation();
  const { data: userDetails, refetch: refetchUserDetails } = useUserDetails(
    user.id,
  );

  const { configuration } = useChainalysisContext();
  const { activeEnvironmentType, activeEnvironment } = useEnvironmentsContext();

  const [sessions, setSessions] = useState<Session[]>(
    userDetails?.sessions || [],
  );

  useEffect(() => {
    if (userDetails && userDetails.sessions) {
      setSessions(userDetails.sessions);
    }
  }, [userDetails]);

  const refreshUserDetails = async () => {
    await refetchUserDetails();
  };

  const areChainAlysisRecordsAvailable =
    userDetails?.chainalysisChecks && userDetails.chainalysisChecks.length > 0;

  const shouldShowChainalysisTab =
    configuration[activeEnvironmentType].sanctionsApiEnabled ||
    areChainAlysisRecordsAvailable;
  const hasEmbeddedWallet = !!userDetails?.verifiedCredentials?.find(
    (vc) => vc.walletProvider === WalletProviderEnum.EmbeddedWallet,
  );
  const hideDelete =
    activeEnvironmentType === EnvironmentEnum.Live && hasEmbeddedWallet;

  return (
    <Portal handleClose={() => setUser(undefined)} className={styles.portal}>
      <>
        <div className={styles.header} data-testid='user-sidebar-info'>
          <div className='flex'>
            <Typography variant='subtitle' weight='medium'>
              {t('users_sidebar_modal.user')} {userDetails?.id}
            </Typography>
            <CopyButton
              textToCopy={userDetails?.id || ''}
              type='icon'
              color='gray'
              className='-top-[2px] ml-[6px]'
            />
          </div>
          <div className='flex'>
            {!hideDelete && (
              <button
                className='mr-5'
                onClick={deleteUser}
                type='button'
                data-testid='delete'
              >
                <TrashIcon />
              </button>
            )}
            <button
              onClick={() => setUser(undefined)}
              type='button'
              data-testid='close'
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <Tabs.Root defaultValue='account'>
          <Tabs.List>
            <Tabs.Trigger className={styles.tabTrigger} value='account'>
              Account
            </Tabs.Trigger>
            <Tabs.Trigger className={styles.tabTrigger} value='sessions'>
              Sessions
            </Tabs.Trigger>
            {shouldShowChainalysisTab && (
              <Tabs.Trigger className={styles.tabTrigger} value='chainalysis'>
                Chainalysis
              </Tabs.Trigger>
            )}
          </Tabs.List>
          <Tabs.Content className='py-6' value='account'>
            <AccountTab userId={user.id} />
          </Tabs.Content>
          <Tabs.Content className='py-6' value='sessions'>
            {sessions && activeEnvironment?.id && (
              <SessionsTable
                sessions={sessions}
                userId={user.id}
                refreshSessions={refreshUserDetails}
                environmentId={activeEnvironment.id}
              />
            )}
          </Tabs.Content>
          {shouldShowChainalysisTab && (
            <Tabs.Content className='py-6' value='chainalysis'>
              <ChainalysisChecksTable
                chainalysisChecks={userDetails?.chainalysisChecks || []}
                walletPublicKey={userDetails?.walletPublicKey}
                chain={userDetails?.chain}
              />
            </Tabs.Content>
          )}
        </Tabs.Root>
      </>
    </Portal>
  );
};

export default UserDetailsModal;
