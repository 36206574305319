import { useState } from 'react';

import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

import 'moment-timezone';
import { RevokeUserSessionsRequest, Session } from '@dynamic-labs/sdk-api';

import { usersApi } from '../../../../../services/api';
import Table from '../../../../../components/Table';
import Button from '../../../../../components/Button';
import { logger } from '../../../../../services/logger';
import { ErrorInfo } from '../../../../../components/ErrorInfo';
import { Typography } from '../../../../../components/Typography';

import styles from './sessions-table.module.css';

interface Props {
  environmentId: string;
  refreshSessions: () => Promise<void>;
  sessions: Session[];
  userId: string;
}

const SessionsTable = ({
  sessions,
  userId,
  environmentId,
  refreshSessions,
}: Props) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleRevokeSessionsClick = async () => {
    try {
      const revokeRequest: RevokeUserSessionsRequest = {
        environmentId,
        userId,
      };
      await usersApi.revokeUserSessions(revokeRequest);
      await refreshSessions();
    } catch (e: any) {
      logger.error(e);
      setErrorMessage(t('users_sidebar_modal.sessions.table.revocation_error'));
    }
  };
  const headers = [
    t('users_sidebar_modal.sessions.table.session'),
    t('users_sidebar_modal.sessions.table.date'),
    t('users_sidebar_modal.sessions.table.time'),
    t('users_sidebar_modal.sessions.table.ip_address'),
    t('users_sidebar_modal.sessions.table.revoked_at'),
  ];
  return (
    <>
      {errorMessage && (
        <ErrorInfo className='mb-5'>
          <span>
            <Typography
              className={styles.errorHeading}
              variant='paragraph-2'
              weight='medium'
            >
              {t('users_sidebar_modal.sessions.table.revocation_error_heading')}
            </Typography>
            <Typography className={styles.errorMessage} variant='paragraph-1'>
              {errorMessage}
            </Typography>
          </span>
        </ErrorInfo>
      )}
      <Table
        headers={headers}
        rows={sessions.map((session, index) => (
          <div
            className={`${styles.row__container} ${styles.row__session}`}
            key={`row_${session.id}`}
            data-testid='session'
          >
            <div className={styles.table__column}>{index + 1}</div>
            <div className={styles.table__column}>
              <Moment date={session.createdAt} format='MM/DD/YYYY' />
            </div>
            <div className={styles.table__column}>
              <Moment
                date={session.createdAt}
                tz='America/Los_Angeles'
                format='hh:mm A (z)'
              />
            </div>
            <div className={styles.table__column}>
              {session.ipAddress ? session.ipAddress : <em>null</em>}
            </div>
            <div className={styles.table__column}>
              {session.revokedAt ? (
                <Moment
                  date={session.revokedAt}
                  tz='America/Los_Angeles'
                  format='MM/DD/YYYY hh:mm A (z)'
                />
              ) : (
                <em>null</em>
              )}
            </div>
          </div>
        ))}
      />
      <Button onClick={handleRevokeSessionsClick}>
        {t('users_sidebar_modal.sessions.table.revokeUserSessions')}
      </Button>
    </>
  );
};

export default SessionsTable;
