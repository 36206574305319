/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimulateSVMTransactionRequest
 */
export interface SimulateSVMTransactionRequest {
    /**
     * The Solana chainId to simulate the transaction on
     * @type {string}
     * @memberof SimulateSVMTransactionRequest
     */
    chain: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulateSVMTransactionRequest
     */
    transactions: Array<string>;
    /**
     * Valid Solana wallet address, must be a 44-character string using base58 characters
     * @type {string}
     * @memberof SimulateSVMTransactionRequest
     */
    accountAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateSVMTransactionRequest
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateSVMTransactionRequest
     */
    method: string;
}

export function SimulateSVMTransactionRequestFromJSON(json: any): SimulateSVMTransactionRequest {
    return SimulateSVMTransactionRequestFromJSONTyped(json, false);
}

export function SimulateSVMTransactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulateSVMTransactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chain': json['chain'],
        'transactions': json['transactions'],
        'accountAddress': json['accountAddress'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'method': json['method'],
    };
}

export function SimulateSVMTransactionRequestToJSON(value?: SimulateSVMTransactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chain': value.chain,
        'transactions': value.transactions,
        'accountAddress': value.accountAddress,
        'domain': value.domain,
        'method': value.method,
    };
}

