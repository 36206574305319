import { ExternalWalletFundingDefaultSettings } from '@dynamic-labs/sdk-api';

import { EnvironmentSettings } from '../../../../app/context/SettingsContext';

const areChainSettingsEqual = (
  settingsA?: ExternalWalletFundingDefaultSettings[],
  settingsB?: ExternalWalletFundingDefaultSettings[],
) => {
  if (!settingsA && !settingsB) return true;
  if (!settingsA || !settingsB) return false;
  if (settingsA.length !== settingsB.length) return false;

  return settingsA.every((settingA) => {
    const matchingSetting = settingsB.find(
      (settingB) => settingB.chain === settingA.chain,
    );
    return matchingSetting && matchingSetting.token === settingA.token;
  });
};

export const didSettingsChange = (
  initialExternalFundingSettings: EnvironmentSettings['live']['sdk']['funding'],
  settings: EnvironmentSettings['live']['sdk']['funding'],
) =>
  initialExternalFundingSettings?.externalWallets?.enabled !==
    settings?.externalWallets?.enabled ||
  !areChainSettingsEqual(
    initialExternalFundingSettings?.externalWallets?.defaultSettings
      ?.chainSettings,
    settings?.externalWallets?.defaultSettings?.chainSettings,
  );
