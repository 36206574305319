/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WalletKeyShareInfo,
    WalletKeyShareInfoFromJSON,
    WalletKeyShareInfoFromJSONTyped,
    WalletKeyShareInfoToJSON,
} from './WalletKeyShareInfo';
import {
    WalletKeyShareInfoWithEncryptedAccountCredentialAllOf,
    WalletKeyShareInfoWithEncryptedAccountCredentialAllOfFromJSON,
    WalletKeyShareInfoWithEncryptedAccountCredentialAllOfFromJSONTyped,
    WalletKeyShareInfoWithEncryptedAccountCredentialAllOfToJSON,
} from './WalletKeyShareInfoWithEncryptedAccountCredentialAllOf';

/**
 * 
 * @export
 * @interface WalletKeyShareInfoWithEncryptedAccountCredential
 */
export interface WalletKeyShareInfoWithEncryptedAccountCredential {
    /**
     * 
     * @type {string}
     * @memberof WalletKeyShareInfoWithEncryptedAccountCredential
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WalletKeyShareInfoWithEncryptedAccountCredential
     */
    backupLocation: string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletKeyShareInfoWithEncryptedAccountCredential
     */
    passwordEncrypted: boolean;
    /**
     * A string with a max length of 4096 characters
     * @type {string}
     * @memberof WalletKeyShareInfoWithEncryptedAccountCredential
     */
    encryptedAccountCredential?: string;
}

export function WalletKeyShareInfoWithEncryptedAccountCredentialFromJSON(json: any): WalletKeyShareInfoWithEncryptedAccountCredential {
    return WalletKeyShareInfoWithEncryptedAccountCredentialFromJSONTyped(json, false);
}

export function WalletKeyShareInfoWithEncryptedAccountCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletKeyShareInfoWithEncryptedAccountCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'backupLocation': json['backupLocation'],
        'passwordEncrypted': json['passwordEncrypted'],
        'encryptedAccountCredential': !exists(json, 'encryptedAccountCredential') ? undefined : json['encryptedAccountCredential'],
    };
}

export function WalletKeyShareInfoWithEncryptedAccountCredentialToJSON(value?: WalletKeyShareInfoWithEncryptedAccountCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'backupLocation': value.backupLocation,
        'passwordEncrypted': value.passwordEncrypted,
        'encryptedAccountCredential': value.encryptedAccountCredential,
    };
}

