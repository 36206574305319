import { ChangeEvent, FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Alert,
  Icon,
  Section,
  SingleToggleCard,
  Typography,
} from '@dynamic-labs/northstar';
import {
  ChainEnum,
  ExternalWalletFundingDefaultSettingsToken,
} from '@dynamic-labs/sdk-api';

import { AdminIcon } from '../../../../../icons';
import { SideDrawer } from '../../../../components/SideDrawer';
import { useSettingsContext } from '../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';
import { ChainTokenConfiguration } from '../components';
import styles from '../../FundingPage.module.scss';

export const ExternalFundingSection: FC = () => {
  const { t } = useTranslation();
  const [showExternalFundingDrawer, setShowExternalFundingDrawer] =
    useState(false);

  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isExternalWalletFundingEnabled = Boolean(
    settings[activeEnvironmentType].sdk?.funding?.externalWallets?.enabled,
  );

  const isSettingSaved = Boolean(
    initialSettings[activeEnvironmentType].sdk?.funding?.externalWallets
      ?.enabled === isExternalWalletFundingEnabled,
  );

  const enabledChains = initialSettings[activeEnvironmentType].chains.filter(
    (chain) => Boolean(chain.enabled),
  );

  const hasEvmChain = enabledChains.some((chain) => chain.name === 'evm');
  const hasEthereumChain = enabledChains.some(
    (chain) => chain.name === 'ethereum',
  );
  const hasSolanaChain = enabledChains.some((chain) => chain.name === 'solana');

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          funding: {
            ...settings[activeEnvironmentType].sdk.funding,
            externalWallets: {
              ...settings[activeEnvironmentType].sdk.funding?.externalWallets,
              enabled: e.target.checked,
            },
          },
        },
      },
    });
  };

  const handleChange = (
    chainName: ChainEnum,
    value: ExternalWalletFundingDefaultSettingsToken,
  ) => {
    const currentChainSettings =
      settings[activeEnvironmentType].sdk.funding?.externalWallets
        ?.defaultSettings?.chainSettings ?? [];

    const settingToUpdateIndex = currentChainSettings.findIndex((setting) =>
      Boolean(setting.chain === chainName),
    );

    const updatedChainSettings = [...currentChainSettings];

    if (settingToUpdateIndex === -1) {
      updatedChainSettings.push({
        chain: chainName,
        token: value,
      });
    } else {
      updatedChainSettings[settingToUpdateIndex] = {
        ...currentChainSettings[settingToUpdateIndex],
        token: value,
      };
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          funding: {
            ...settings[activeEnvironmentType].sdk.funding,
            externalWallets: {
              ...settings[activeEnvironmentType].sdk.funding?.externalWallets,
              defaultSettings: {
                ...settings[activeEnvironmentType].sdk.funding?.externalWallets
                  ?.defaultSettings,
                chainSettings: updatedChainSettings,
              },
            },
          },
        },
      },
    });
  };

  const chainConfigurations = [
    {
      chainName: ChainEnum.Evm,
      displayName: 'EVM',
      isEnabled: hasEthereumChain || hasEvmChain,
      options: [
        {
          label: 'ETH',
          value: 'ETH',
        },
        {
          label: 'USDC',
          value: 'USDC',
        },
        {
          label: 'USDT',
          value: 'USDT',
        },
        {
          label: 'Other',
          value: 'OTHER',
        },
      ],
    },
    {
      chainName: ChainEnum.Sol,
      displayName: 'Solana',
      isEnabled: hasSolanaChain,
      options: [
        {
          label: 'SOL',
          value: 'SOL',
        },
        {
          label: 'USDC',
          value: 'USDC',
        },
        {
          label: 'Other',
          value: 'OTHER',
        },
      ],
    },
  ];

  return (
    <div className={styles.sectionContainer}>
      <Section
        title={t('v2.page.funding.sections.external_funding.title')}
        description={t('v2.page.funding.sections.external_funding.description')}
      >
        <SingleToggleCard
          accordionKey='wallets-enabled'
          title={t('v2.page.funding.sections.external_funding.title')}
          allowExpand={false}
          data-testid='external-wallets-toggle'
          onCardClick={() => setShowExternalFundingDrawer(true)}
          customActionIcon={{
            Icon: <Icon icon={<AdminIcon />} color='primary-1' />,
          }}
          inputProps={{
            checked: isExternalWalletFundingEnabled,
            id: 'external-wallets-enabled',
            isSaved: isSettingSaved,
            onChange: handleToggle,
            type: 'toggle',
          }}
        />
      </Section>
      <SideDrawer
        handleClose={() => setShowExternalFundingDrawer(false)}
        isOpen={showExternalFundingDrawer}
        title={t('v2.page.funding.sections.external_funding.drawer.title')}
        description={t(
          'v2.page.funding.sections.external_funding.drawer.description',
        )}
        className={styles.drawer}
      >
        <div className={styles.drawerContent}>
          <Typography variant='subtitle' weight='medium' color='gray-1'>
            {t(
              'v2.page.funding.sections.external_funding.drawer.description_2',
            )}{' '}
            <a
              href='/dashboard/chains-and-networks'
              target='_blank'
              rel='noreferrer'
              className='text-primary-1'
            >
              {t(
                'v2.page.funding.sections.external_funding.drawer.description_2_link',
              )}
            </a>
          </Typography>
        </div>
        <div className={styles.chainConfigContainer}>
          {chainConfigurations
            .filter((chainConfiguration) => chainConfiguration.isEnabled)
            .map((chainConfiguration) => (
              <ChainTokenConfiguration
                displayName={chainConfiguration.displayName}
                key={chainConfiguration.chainName}
                chainName={chainConfiguration.chainName}
                initialValue={
                  settings[
                    activeEnvironmentType
                  ].sdk.funding?.externalWallets?.defaultSettings?.chainSettings?.find(
                    (setting) => setting.chain === chainConfiguration.chainName,
                  )?.token
                }
                onChange={handleChange}
                options={chainConfiguration.options}
                placeholder={t(
                  'v2.page.funding.sections.external_funding.customizations.amount_and_currency.inputs.minAmount.amountPlaceholder',
                )}
              />
            ))}
        </div>
        <div className={styles.alertContainer}>
          <Alert
            title={t(
              'v2.page.funding.sections.external_funding.drawer.alert.title',
            )}
            description={t(
              'v2.page.funding.sections.external_funding.drawer.alert.description',
            )}
            variant='advance'
          />
        </div>
      </SideDrawer>
    </div>
  );
};
