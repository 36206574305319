/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-classes-per-file */
import {
  JwtVerifiedCredentialFormatEnum,
  WalletProviderEnum,
} from '@dynamic-labs/sdk-api';
import {
  UserProfile,
  Chain,
  WalletConnector,
} from '@dynamic-labs/sdk-react-core';
import {
  Wallet,
  WalletConnectorBase,
} from '@dynamic-labs/wallet-connector-core';
import { IDynamicContext } from '@dynamic-labs/sdk-react-core/src/lib/context/DynamicContext';

import { VALID_URL_REGEXP } from './regexp';

class BaseClass {
  constructor(props?: any) {
    // @ts-ignore
    this.constructorProps = props;
    // @ts-ignore
    this._walletBookInstance = {
      walletBook: {} as any,
    };
  }
}
const BaseClassWithType = BaseClass as unknown as typeof WalletConnectorBase;
Object.setPrototypeOf(
  BaseClassWithType.prototype,
  WalletConnectorBase.prototype,
);

class ChainWallet extends Wallet {
  override async sendBalance(): Promise<string | undefined> {
    return undefined;
  }
}

class DefaultWalletConnector extends BaseClassWithType {
  name = 'MetaMask';

  supportedChains = [];

  connectedChain: Chain = 'ETH';

  override ChainWallet = ChainWallet;

  override get metadata() {
    return {
      icon: 'https://iconic.dynamic-static-assets.com/icons/sprite.svg#metamask',
      id: 'metamask',
      name: 'MetaMask',
    };
  }
}

export const walletConnectorDefault: WalletConnector =
  new DefaultWalletConnector({ walletBook: undefined as unknown as any });

export const dynamicContextDefault: IDynamicContext = {
  accessDeniedMessagePrimary: '',
  accessDeniedMessageSecondary: '',
  appLogoUrl: '',
  appName: '',
  authToken: '',
  authorizationViewDisplayOrder: 'web2',
  clearStatesOnBackClick: () => {},
  connectedWallets: [],
  debugError: false,
  defaultNumberOfWalletsToShow: 10,
  environmentId: '',
  getNameService: async () => undefined,
  handleUnlinkWallet: () => {},
  multiWalletWidgetState: 'idle',
  network: 12345,
  newToWeb3WalletChainMap: {
    primary_chain: 'evm',
    wallets: {
      algorand: 'myalgo',
      cosmos: 'keplr',
      evm: 'metamask',
      flow: 'blocto',
      solana: 'phantom',
    },
  },
  notInTheListImageUrl: '',
  onboardingImageUrl: '',
  primaryWallet: null,
  privacyPolicyUrl: '',
  projectSettings: {
    general: {
      collectUserDataWelcomeHeader: 'Welcome to Dynamic ',
      collectUserDataWelcomeMessage:
        'We need a bit of information to get started',
    },
  } as IDynamicContext['projectSettings'],
  qrcodeUri: 'hello world!'.repeat(20),
  secondaryWallets: [],
  selectedWalletConnector: walletConnectorDefault,
  setMultiWalletWidgetState: () => {},
  setShowAuthFlow: () => {},
  setUser: () => {},
  showAuthFlow: false,
  socialMediaIconUrl: undefined,
  socialMediaLinkText: '',
  socialMediaUrl: '',
  termsOfServiceUrl: '',
  theme: undefined,
  user: undefined,
  userWithMissingInfo: {
    lastVerifiedCredentialId: '3e403def-05a1-489c-93be-72e9ea45aac1',
    verifiedCredentials: [
      {
        address: '0xc...f53',
        chain: 'ETH',
        format: JwtVerifiedCredentialFormatEnum.Blockchain,
        id: '3e403def-05a1-489c-93be-72e9ea45aac1',
        walletName: 'MetaMask',
      },
    ],
  } as IDynamicContext['userWithMissingInfo'],
  walletConnector: null,
  walletConnectorOptions: [
    {
      isInstalledOnBrowser: true,
      key: 'phantom',
      name: 'Phantom',
      walletConnector: {
        canConnectViaCustodialService: false,
        canConnectViaQrCode: false,
        connectedChain: 'SOL',
        isAvailable: true,
        isWalletConnect: false,
        metadata: {
          icon: 'https://iconic.dynamic-static-assets.com/icons/sprite.svg#phantom',
          id: 'phantom',
          name: 'Phantom',
        },
        solNetworks: [
          {
            blockExplorerUrls: ['https://explorer.solana.com'],
            chain: 'SOL',
            chainId: '101',
            iconUrls: ['https://app.dynamic.xyz/assets/networks/solana.svg'],
            name: 'Solana Mainnet',
            nativeCurrency: {
              decimals: 18,
              name: 'Solana',
              symbol: 'SOL',
            },
            networkId: '101',
            privateCustomerRpcUrls: ['https://api.mainnet-beta.solana.com'],
            rpcUrls: ['https://api.mainnet-beta.solana.com'],
            shortName: 'sol',
          },
        ],
        solProviderHelper: {
          providers: {},
        },
        supportedChains: ['SOL'],
      },
    },
    {
      isInstalledOnBrowser: true,
      key: 'metamask',
      name: 'MetaMask',
      walletConnector: {
        canConnectViaCustodialService: false,
        canConnectViaQrCode: false,
        connectedChain: 'EVM',
        ethProviderHelper: {
          providers: {},
        },
        evmNetworks: [],
        isAvailable: true,
        isWalletConnect: false,
        metadata: {
          icon: 'https://iconic.dynamic-static-assets.com/icons/sprite.svg#metamask',
          id: 'metamask',
          name: 'MetaMask',
        },
        name: 'MetaMask',
        supportedChains: ['ETH', 'EVM'],
      },
    } as any,
    {
      isInstalledOnBrowser: true,
      key: 'glow',
      name: 'Glow',
      walletConnector: {
        canConnectViaCustodialService: false,
        canConnectViaQrCode: false,
        connectedChain: 'SOL',
        isAvailable: true,
        isWalletConnect: false,
        metadata: {
          icon: 'https://iconic.dynamic-static-assets.com/icons/sprite.svg#glow',
          id: 'glow',
          name: 'Glow',
        },
        solNetworks: [],
        solProviderHelper: {
          providers: {},
        },
        supportedChains: ['SOL'],
      },
    },
  ] as IDynamicContext['walletConnectorOptions'],
} as any;

export const userInfoDefault: UserProfile = {
  environmentId: 'environment_id',
  lastVerifiedCredentialId: 'id',
  lists: [],
  missingFields: [],
  sessionId: 'someSessionId',
  userId: 'sub',
  verifiedCredentials: [
    {
      address: 'walletPublicKey',
      chain: 'EVM',
      format: JwtVerifiedCredentialFormatEnum.Blockchain,
      id: 'id',
      signInEnabled: true,
      walletName: 'metamask',
      walletProvider: WalletProviderEnum.BrowserExtension,
    },
  ],
};

export const openWindowUrl = (
  url: string | undefined,
  openInBlankMode = true,
) => {
  if (url && !VALID_URL_REGEXP.test(url)) {
    return;
  }

  window.open(url, openInBlankMode ? '_blank' : undefined);
};

export const formatDateToInternationalFormat = (date: Date) => {
  const month = date.toLocaleString('en-US', {
    month: 'short',
  });

  const day = date.toLocaleString(undefined, {
    day: '2-digit',
  });

  return `${
    month.charAt(0).toUpperCase() + month.slice(1)
  } ${day}, ${date.getFullYear()}`;
};
