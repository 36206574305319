export const dynamicSupportedCountries: CountryCode[] = ['US', 'CA'];

// these are the countries was populated based on the wikipedia page
// referenced in twilio's docs: https://www.twilio.com/docs/voice/api/dialingpermissions-country-resource
// and this doc for country codes: https://www.itu.int/itudoc/itu-t/ob-lists/icc/e164_763.pdf
export type CountryCode =
  | 'AC'
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'IC'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SI'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'ZA'
  | 'ZM'
  | 'ZW';

export type CountryData = {
  code: string;
  name: string;
};

export type CountryPhoneData = Record<CountryCode, CountryData>;

export const supportedCountries: CountryPhoneData = {
  AC: {
    code: '247',
    name: 'Ascension',
  },
  AD: {
    code: '376',
    name: 'Andorra',
  },
  AE: {
    code: '971',
    name: 'United Arab Emirates',
  },
  AF: {
    code: '93',
    name: 'Afghanistan',
  },
  AG: {
    code: '1268',
    name: 'Antigua and Barbuda',
  },
  AI: {
    code: '1264',
    name: 'Anguilla',
  },
  AL: {
    code: '355',
    name: 'Albania',
  },
  AM: {
    code: '374',
    name: 'Armenia',
  },
  AO: {
    code: '244',
    name: 'Angola',
  },
  AR: {
    code: '54',
    name: 'Argentina',
  },
  AS: {
    code: '1684',
    name: 'American Samoa',
  },
  AT: {
    code: '43',
    name: 'Austria',
  },
  AU: {
    code: '61',
    name: 'Australia/Cocos/Christmas Island',
  },
  AW: {
    code: '297',
    name: 'Aruba',
  },
  AZ: {
    code: '994',
    name: 'Azerbaijan',
  },
  BA: {
    code: '387',
    name: 'Bosnia and Herzegovina',
  },
  BB: {
    code: '1246',
    name: 'Barbados',
  },
  BD: {
    code: '880',
    name: 'Bangladesh',
  },
  BE: {
    code: '32',
    name: 'Belgium',
  },
  BF: {
    code: '226',
    name: 'Burkina Faso',
  },
  BG: {
    code: '359',
    name: 'Bulgaria',
  },
  BH: {
    code: '973',
    name: 'Bahrain',
  },
  BI: {
    code: '257',
    name: 'Burundi',
  },
  BJ: {
    code: '229',
    name: 'Benin',
  },
  BM: {
    code: '1441',
    name: 'Bermuda',
  },
  BN: {
    code: '673',
    name: 'Brunei',
  },
  BO: {
    code: '591',
    name: 'Bolivia',
  },
  BR: {
    code: '55',
    name: 'Brazil',
  },
  BS: {
    code: '1242',
    name: 'Bahamas',
  },
  BT: {
    code: '975',
    name: 'Bhutan',
  },
  BW: {
    code: '267',
    name: 'Botswana',
  },
  BY: {
    code: '375',
    name: 'Belarus',
  },
  BZ: {
    code: '501',
    name: 'Belize',
  },
  CA: {
    code: '1',
    name: 'Canada',
  },
  CD: {
    code: '243',
    name: 'Congo, Dem Rep',
  },
  CF: {
    code: '236',
    name: 'Central Africa',
  },
  CG: {
    code: '242',
    name: 'Congo',
  },
  CH: {
    code: '41',
    name: 'Switzerland',
  },
  CI: {
    code: '225',
    name: 'Ivory Coast',
  },
  CK: {
    code: '682',
    name: 'Cook Islands',
  },
  CL: {
    code: '56',
    name: 'Chile',
  },
  CM: {
    code: '237',
    name: 'Cameroon',
  },
  CN: {
    code: '86',
    name: 'China',
  },
  CO: {
    code: '57',
    name: 'Colombia',
  },
  CR: {
    code: '506',
    name: 'Costa Rica',
  },
  CU: {
    code: '53',
    name: 'Cuba',
  },
  CV: {
    code: '238',
    name: 'Cape Verde',
  },
  CW: {
    code: '599',
    name: 'Curaçao and Caribbean Netherlands (Bonaire, Sint Eustatius, Sint Maarten, Saba)',
  },
  CY: {
    code: '357',
    name: 'Cyprus',
  },
  CZ: {
    code: '420',
    name: 'Czech Republic',
  },
  DE: {
    code: '49',
    name: 'Germany',
  },
  DJ: {
    code: '253',
    name: 'Djibouti',
  },
  DK: {
    code: '45',
    name: 'Denmark',
  },
  DM: {
    code: '1767',
    name: 'Dominica',
  },
  DO: {
    code: '1829',
    name: 'Dominican Republic',
  },
  DZ: {
    code: '213',
    name: 'Algeria',
  },
  EC: {
    code: '593',
    name: 'Ecuador',
  },
  EE: {
    code: '372',
    name: 'Estonia',
  },
  EG: {
    code: '20',
    name: 'Egypt',
  },
  ER: {
    code: '291',
    name: 'Eritrea',
  },
  ES: {
    code: '34',
    name: 'Spain',
  },
  ET: {
    code: '251',
    name: 'Ethiopia',
  },
  FI: {
    code: '358',
    name: 'Finland/Aland Islands',
  },
  FJ: {
    code: '679',
    name: 'Fiji',
  },
  FK: {
    code: '500',
    name: 'Falkland Islands',
  },
  FM: {
    code: '691',
    name: 'Micronesia',
  },
  FO: {
    code: '298',
    name: 'Faroe Islands',
  },
  FR: {
    code: '33',
    name: 'France',
  },
  GA: {
    code: '241',
    name: 'Gabon',
  },
  GB: {
    code: '44',
    name: 'United Kingdom',
  },
  GD: {
    code: '1473',
    name: 'Grenada',
  },
  GE: {
    code: '995',
    name: 'Georgia',
  },
  GF: {
    code: '594',
    name: 'French Guiana',
  },
  GG: {
    code: '44',
    name: 'Guernsey/Jersey',
  },
  GH: {
    code: '233',
    name: 'Ghana',
  },
  GI: {
    code: '350',
    name: 'Gibraltar',
  },
  GL: {
    code: '299',
    name: 'Greenland',
  },
  GM: {
    code: '220',
    name: 'Gambia',
  },
  GN: {
    code: '224',
    name: 'Guinea',
  },
  GP: {
    code: '590',
    name: 'Guadeloupe',
  },
  GQ: {
    code: '240',
    name: 'Equatorial Guinea',
  },
  GR: {
    code: '30',
    name: 'Greece',
  },
  GT: {
    code: '502',
    name: 'Guatemala',
  },
  GU: {
    code: '1671',
    name: 'Guam',
  },
  GW: {
    code: '245',
    name: 'Guinea-Bissau',
  },
  GY: {
    code: '592',
    name: 'Guyana',
  },
  HK: {
    code: '852',
    name: 'Hong Kong',
  },
  HN: {
    code: '504',
    name: 'Honduras',
  },
  HR: {
    code: '385',
    name: 'Croatia',
  },
  HT: {
    code: '509',
    name: 'Haiti',
  },
  HU: {
    code: '36',
    name: 'Hungary',
  },
  IC: {
    code: '3491',
    name: 'Canary Islands',
  },
  ID: {
    code: '62',
    name: 'Indonesia',
  },
  IE: {
    code: '353',
    name: 'Ireland',
  },
  IL: {
    code: '972',
    name: 'Israel',
  },
  IM: {
    code: '44',
    name: 'Isle of Man',
  },
  IN: {
    code: '91',
    name: 'India',
  },
  IQ: {
    code: '964',
    name: 'Iraq',
  },
  IR: {
    code: '98',
    name: 'Iran',
  },
  IS: {
    code: '354',
    name: 'Iceland',
  },
  IT: {
    code: '39',
    name: 'Italy',
  },
  JM: {
    code: '1876',
    name: 'Jamaica',
  },
  JO: {
    code: '962',
    name: 'Jordan',
  },
  JP: {
    code: '81',
    name: 'Japan',
  },
  KE: {
    code: '254',
    name: 'Kenya',
  },
  KG: {
    code: '996',
    name: 'Kyrgyzstan',
  },
  KH: {
    code: '855',
    name: 'Cambodia',
  },
  KI: {
    code: '686',
    name: 'Kiribati',
  },
  KM: {
    code: '269',
    name: 'Comoros',
  },
  KN: {
    code: '1869',
    name: 'St Kitts and Nevis',
  },
  KP: {
    code: '850',
    name: "Korea Dem People's Rep",
  },
  KR: {
    code: '82',
    name: 'Korea Republic of',
  },
  KW: {
    code: '965',
    name: 'Kuwait',
  },
  KY: {
    code: '1345',
    name: 'Cayman Islands',
  },
  LA: {
    code: '856',
    name: 'Laos PDR',
  },
  LB: {
    code: '961',
    name: 'Lebanon',
  },
  LC: {
    code: '1758',
    name: 'St Lucia',
  },
  LI: {
    code: '423',
    name: 'Liechtenstein',
  },
  LK: {
    code: '94',
    name: 'Sri Lanka',
  },
  LR: {
    code: '231',
    name: 'Liberia',
  },
  LS: {
    code: '266',
    name: 'Lesotho',
  },
  LT: {
    code: '370',
    name: 'Lithuania',
  },
  LU: {
    code: '352',
    name: 'Luxembourg',
  },
  LV: {
    code: '371',
    name: 'Latvia',
  },
  LY: {
    code: '218',
    name: 'Libya',
  },
  MA: {
    code: '212',
    name: 'Morocco/Western Sahara',
  },
  MC: {
    code: '377',
    name: 'Monaco',
  },
  MD: {
    code: '373',
    name: 'Moldova',
  },
  ME: {
    code: '382',
    name: 'Montenegro',
  },
  MG: {
    code: '261',
    name: 'Madagascar',
  },
  MH: {
    code: '692',
    name: 'Marshall Islands',
  },
  MK: {
    code: '389',
    name: 'Republic of North Macedonia',
  },
  ML: {
    code: '223',
    name: 'Mali',
  },
  MM: {
    code: '95',
    name: 'Myanmar (Burma)',
  },
  MN: {
    code: '976',
    name: 'Mongolia',
  },
  MO: {
    code: '853',
    name: 'Macau',
  },
  MP: {
    code: '1670',
    name: 'Northern Mariana Islands',
  },
  MQ: {
    code: '596',
    name: 'Martinique',
  },
  MR: {
    code: '222',
    name: 'Mauritania',
  },
  MS: {
    code: '1664',
    name: 'Montserrat',
  },
  MT: {
    code: '356',
    name: 'Malta',
  },
  MU: {
    code: '230',
    name: 'Mauritius',
  },
  MV: {
    code: '960',
    name: 'Maldives',
  },
  MW: {
    code: '265',
    name: 'Malawi',
  },
  MX: {
    code: '52',
    name: 'Mexico',
  },
  MY: {
    code: '60',
    name: 'Malaysia',
  },
  MZ: {
    code: '258',
    name: 'Mozambique',
  },
  NA: {
    code: '264',
    name: 'Namibia',
  },
  NC: {
    code: '687',
    name: 'New Caledonia',
  },
  NE: {
    code: '227',
    name: 'Niger',
  },
  NF: {
    code: '672',
    name: 'Norfolk Island',
  },
  NG: {
    code: '234',
    name: 'Nigeria',
  },
  NI: {
    code: '505',
    name: 'Nicaragua',
  },
  NL: {
    code: '31',
    name: 'Netherlands',
  },
  NO: {
    code: '47',
    name: 'Norway',
  },
  NP: {
    code: '977',
    name: 'Nepal',
  },
  NU: {
    code: '683',
    name: 'Niue',
  },
  NZ: {
    code: '64',
    name: 'New Zealand',
  },
  OM: {
    code: '968',
    name: 'Oman',
  },
  PA: {
    code: '507',
    name: 'Panama',
  },
  PE: {
    code: '51',
    name: 'Peru',
  },
  PF: {
    code: '689',
    name: 'French Polynesia',
  },
  PG: {
    code: '675',
    name: 'Papua New Guinea',
  },
  PH: {
    code: '63',
    name: 'Philippines',
  },
  PK: {
    code: '92',
    name: 'Pakistan',
  },
  PL: {
    code: '48',
    name: 'Poland',
  },
  PM: {
    code: '508',
    name: 'St Pierre and Miquelon',
  },
  PR: {
    code: '1787',
    name: 'Puerto Rico',
  },
  PS: {
    code: '970, 972',
    name: 'Palestinian Territory',
  },
  PT: {
    code: '351',
    name: 'Portugal',
  },
  PW: {
    code: '680',
    name: 'Palau',
  },
  PY: {
    code: '595',
    name: 'Paraguay',
  },
  QA: {
    code: '974',
    name: 'Qatar',
  },
  RE: {
    code: '262',
    name: 'Reunion/Mayotte',
  },
  RO: {
    code: '40',
    name: 'Romania',
  },
  RS: {
    code: '381',
    name: 'Serbia',
  },
  RU: {
    code: '7',
    name: 'Russia/Kazakhstan',
  },
  RW: {
    code: '250',
    name: 'Rwanda',
  },
  SA: {
    code: '966',
    name: 'Saudi Arabia',
  },
  SB: {
    code: '677',
    name: 'Solomon Islands',
  },
  SC: {
    code: '248',
    name: 'Seychelles',
  },
  SD: {
    code: '249',
    name: 'Sudan',
  },
  SE: {
    code: '46',
    name: 'Sweden',
  },
  SG: {
    code: '65',
    name: 'Singapore',
  },
  SI: {
    code: '386',
    name: 'Slovenia',
  },
  SK: {
    code: '421',
    name: 'Slovakia',
  },
  SL: {
    code: '232',
    name: 'Sierra Leone',
  },
  SM: {
    code: '378',
    name: 'San Marino',
  },
  SN: {
    code: '221',
    name: 'Senegal',
  },
  SO: {
    code: '252',
    name: 'Somalia',
  },
  SR: {
    code: '597',
    name: 'Suriname',
  },
  SS: {
    code: '211',
    name: 'South Sudan',
  },
  ST: {
    code: '239',
    name: 'Sao Tome and Principe',
  },
  SV: {
    code: '503',
    name: 'El Salvador',
  },
  SY: {
    code: '963',
    name: 'Syria',
  },
  SZ: {
    code: '268',
    name: 'Swaziland',
  },
  TC: {
    code: '1649',
    name: 'Turks and Caicos Islands',
  },
  TD: {
    code: '235',
    name: 'Chad',
  },
  TG: {
    code: '228',
    name: 'Togo',
  },
  TH: {
    code: '66',
    name: 'Thailand',
  },
  TJ: {
    code: '992',
    name: 'Tajikistan',
  },
  TL: {
    code: '670',
    name: 'East Timor',
  },
  TM: {
    code: '993',
    name: 'Turkmenistan',
  },
  TN: {
    code: '216',
    name: 'Tunisia',
  },
  TO: {
    code: '676',
    name: 'Tonga',
  },
  TR: {
    code: '90',
    name: 'Turkiye',
  },
  TT: {
    code: '1868',
    name: 'Trinidad and Tobago',
  },
  TV: {
    code: '688',
    name: 'Tuvalu',
  },
  TW: {
    code: '886',
    name: 'Taiwan',
  },
  TZ: {
    code: '255',
    name: 'Tanzania',
  },
  UA: {
    code: '380',
    name: 'Ukraine',
  },
  UG: {
    code: '256',
    name: 'Uganda',
  },
  US: {
    code: '1',
    name: 'United States',
  },
  UY: {
    code: '598',
    name: 'Uruguay',
  },
  UZ: {
    code: '998',
    name: 'Uzbekistan',
  },
  VA: {
    code: '379',
    name: 'Vatican City',
  },
  VC: {
    code: '1784',
    name: 'St Vincent Grenadines',
  },
  VE: {
    code: '58',
    name: 'Venezuela',
  },
  VG: {
    code: '1284',
    name: 'Virgin Islands, British',
  },
  VI: {
    code: '1340',
    name: 'Virgin Islands, U.S.',
  },
  VN: {
    code: '84',
    name: 'Vietnam',
  },
  VU: {
    code: '678',
    name: 'Vanuatu',
  },
  WF: {
    code: '681',
    name: 'Wallis and Futuna',
  },
  WS: {
    code: '685',
    name: 'Samoa',
  },
  XK: {
    code: '383',
    name: 'Kosovo',
  },
  YE: {
    code: '967',
    name: 'Yemen',
  },
  ZA: {
    code: '27',
    name: 'South Africa',
  },
  ZM: {
    code: '260',
    name: 'Zambia',
  },
  ZW: {
    code: '263',
    name: 'Zimbabwe',
  },
};
