import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { CopyIcon, EditableTable, Typography } from '@dynamic-labs/northstar';

import { useOriginsContext } from '../../../../../../app/context/OriginsContext';

import styles from './CorsSidePage.module.scss';

export const CorsSidePage: FC = () => {
  const { t } = useTranslation();

  const { addOrigin, deleteOrigin, originData, isLoadingOrigins } =
    useOriginsContext();

  const copyOrigin = (origin: string) => {
    navigator.clipboard.writeText(origin);
  };

  const tableItems =
    originData?.map(({ createdAt, origin, id }, index) => ({
      'Created at': (
        <Typography variant='paragraph-2' weight='medium'>
          {createdAt?.toLocaleDateString()}
        </Typography>
      ),
      ID: (
        <Typography variant='paragraph-2' weight='medium'>
          #{index + 1}
        </Typography>
      ),
      Origin: (
        <button
          className={styles.origin}
          type='button'
          onClick={() => copyOrigin(origin ?? '')}
        >
          <Typography
            variant='paragraph-2'
            weight='medium'
            className={styles.text}
          >
            {origin}
          </Typography>
          <CopyIcon className={styles.copy} />
        </button>
      ),
      key: id ?? '',
    })) ?? [];

  return (
    <div className={styles.content}>
      <Typography variant='paragraph-2' weight='bold'>
        {t(
          'v2.page.security.cors_and_cookies_auth_row.cors_row.side_drawer.add_origin',
        )}
      </Typography>

      <EditableTable
        columnsAndProportions={[
          { label: 'ID', proportion: 0.1 },
          { label: 'Origin', proportion: 0.7 },
          { label: 'Created at', proportion: 0.2 },
        ]}
        items={tableItems}
        rowHeight={40}
        emptyLabel={t(
          isLoadingOrigins
            ? 'v2.page.security.cors_and_cookies_auth_row.cors_row.side_drawer.empty_loading'
            : 'v2.page.security.cors_and_cookies_auth_row.cors_row.side_drawer.empty',
        )}
        onAdd={(origin) => addOrigin(origin)}
        onDelete={(targetIndex) =>
          deleteOrigin(originData ? originData[targetIndex].id ?? '' : '')
        }
        addButtonLabel={t(
          'v2.page.security.cors_and_cookies_auth_row.cors_row.side_drawer.button',
        )}
      />
    </div>
  );
};
