import _ from 'lodash';

import { Provider } from '@dynamic-labs/sdk-api';

/**
 * Determines if the given providers have changed based on
 * their `enabledAt`, `clientId`, `entryPointVersion`, `kernelVersion`, `ecdsaProviderType`,
 * and `multichainAccountAbstractionProviders` properties.
 *
 * @param {Provider} provider - The current provider.
 * @param {Provider} originalProvider - The previously saved provider.
 * @returns {boolean} Returns `true` if the providers have changed, otherwise `false`.
 */
export const didProviderChange = (
  provider: Provider,
  originalProvider: Provider,
): boolean =>
  Boolean(provider.enabledAt) !== Boolean(originalProvider.enabledAt) ||
  provider.clientId !== originalProvider.clientId ||
  provider.entryPointVersion !== originalProvider.entryPointVersion ||
  provider.kernelVersion !== originalProvider.kernelVersion ||
  provider.ecdsaProviderType !== originalProvider.ecdsaProviderType ||
  provider.enableKernelV3Migration !==
    originalProvider.enableKernelV3Migration ||
  provider.enableEIP7702 !== originalProvider.enableEIP7702 ||
  !_.isEqual(
    provider.multichainAccountAbstractionProviders?.map((p) => p.clientId) ||
      [],
    originalProvider.multichainAccountAbstractionProviders?.map(
      (p) => p.clientId,
    ) || [],
  );
