import { useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  ExportModelEnum,
  User,
  UserFilterableFieldsEnum,
} from '@dynamic-labs/sdk-api';

import ReactTable from '../../../components/ReactTable';
import { useDashboardContext } from '../../../context/DashboardContext';
import { useEnvironmentsContext } from '../../../context/EnvironmentsContext';
import { Pagination } from '../../../components/Pagination';
import PopupModal from '../../../components/PopupModal';
import { PopupHeader } from '../../../components/PopupModal/PopupHeader/PopupHeader';
import { ColumnsDropdown, TableFilters } from '../components';
import { Alert } from '../../../components/Alert';
import { useSubscriptionContext } from '../../../context/SubscriptionContext';
import { UpsellBanner } from '../../../components/UpsellBanner';
import { useSubscriptionLock } from '../../../hooks/useSubscriptionLock';
import Button from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import { logger } from '../../../services/logger';

import styles from './users.module.css';
import {
  useFetchUsers,
  useUsersColumns,
  useUsersSearchFilter,
  useDeleteUser,
} from './helpers';
import UserDetailsModal from './UserDetailsModal';
import { ExportsModal } from './ExportsModal';

const AUTHENTICATED_USER_DOCS_URL =
  'https://docs.dynamic.xyz/branded-wallets/connected-vs-authenticated';

const UsersRoute = () => {
  const [user, setUser] = useState<User | undefined>();
  const [showExportsModal, setShowExportsModal] = useState(false);
  const { columns, columnsToToggle, hiddenColumns, setHiddenColumns } =
    useUsersColumns();

  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner } = useSubscriptionLock();

  const {
    usersSearchFilter,
    setUsersSearchFilter,
    usersChainFilter,
    setUsersChainFilter,
    usersProviderFilter,
    setUsersProviderFilter,
  } = useUsersSearchFilter();

  const { activeProjectId } = useDashboardContext();

  const {
    offset,
    page,
    setPage,
    limit,
    total,
    setOffset,
    setUserSearchQuery,
    setSortBy,
    usersData,
    setReload,
  } = useFetchUsers({
    activeProjectId,
    selectedEnvironmentId: activeEnvironment?.id,
    usersChainFilter,
    usersProviderFilter,
    usersSearchFilter,
  });

  const { confirm, loading, deleteUser, toggleConfirm } = useDeleteUser();

  /* istanbul ignore next */
  const handleClick = async () => {
    if (!activeEnvironment?.id) {
      logger.error('No active environment found');
      return;
    }

    await deleteUser({
      environmentId: activeEnvironment?.id || '',
      userId: user?.id || '',
    });
    toggleConfirm();
    setUser(undefined);
    setReload(true);
  };

  const filters = Object.values(UserFilterableFieldsEnum).filter(
    (x) => x !== UserFilterableFieldsEnum.All,
  );

  return (
    <div className={styles.container}>
      {shouldShowTrialBanner && (
        <UpsellBanner daysLeft={subscription?.trialDaysLeft} className='mb-4' />
      )}
      <Alert className={styles.alert} severity='info'>
        <Trans i18nKey='users_management_table.warning'>
          The table below shows authenticated users - those users that have
          signed their wallets. For more information, see the docs
          <a
            href={AUTHENTICATED_USER_DOCS_URL}
            className={styles['alert--link']}
          >
            here
          </a>
          .
        </Trans>
      </Alert>
      <div className={styles['filters-section']}>
        <TableFilters
          filters={filters}
          onSearchQuery={setUserSearchQuery}
          searchFilter={usersSearchFilter}
          setSearchFilter={setUsersSearchFilter}
          chainFilter={usersChainFilter}
          setChainFilter={setUsersChainFilter}
          providerFilter={usersProviderFilter}
          setProviderFilter={setUsersProviderFilter}
        />
        <div className='flex items-center gap-4'>
          <ColumnsDropdown
            setHiddenColumns={setHiddenColumns}
            columnsIds={columnsToToggle}
            hiddenColumns={hiddenColumns}
          />
          <Button onClick={() => setShowExportsModal(true)}>
            {t<string>('data_export.export_data')}
          </Button>
        </div>
      </div>

      <div className={styles.table__container}>
        <div data-testid='user_management'>
          <ReactTable
            columns={columns}
            rowProps={(row) => ({
              className: 'cursor-pointer',
              onClick: () => setUser(row.original),
            })}
            data={usersData}
            withSortParams={{ onSort: (sortParam) => setSortBy(sortParam) }}
            withHiddenColumns={hiddenColumns}
            infoBanner={
              activeEnvironmentType === 'sandbox'
                ? {
                    content: t(
                      'users_management_table.sandbox_limit_users_message',
                    ),
                    link: 'https://docs.dynamic.xyz/developer-dashboard/sandbox-vs-live',
                    linkText: t('users_management_table.sandbox'),
                  }
                : undefined
            }
          />
        </div>
      </div>

      {total > 0 && (
        <Pagination
          total={total}
          forcePage={page}
          onPageChange={({ selected }) => {
            setOffset(selected * limit);
            setPage(selected);
          }}
          limit={limit}
          currentPageTotal={usersData?.length}
          offset={offset}
        />
      )}

      {confirm && (
        <PopupModal handleClose={toggleConfirm}>
          <PopupHeader
            handleClose={toggleConfirm}
            headingText={t('users_sidebar_modal.delete_user_modal.title')}
          />
          <div>
            <Typography variant='paragraph-2' className={styles.errorHeading}>
              {t('users_sidebar_modal.delete_user_modal.content') as string}
            </Typography>
            <div className='flex justify-end gap-3'>
              <Button variant='secondary' onClick={toggleConfirm}>
                {
                  t(
                    'users_sidebar_modal.delete_user_modal.cancelButton',
                  ) as string
                }
              </Button>
              <Button variant='delete' onClick={handleClick} loading={loading}>
                {t('users_sidebar_modal.delete_user_modal.delete') as string}
              </Button>
            </div>
          </div>
        </PopupModal>
      )}

      <AnimatePresence>
        {user && (
          <UserDetailsModal
            deleteUser={toggleConfirm}
            user={user}
            setUser={setUser}
          />
        )}

        {showExportsModal && (
          <ExportsModal
            setShowExportsModal={setShowExportsModal}
            model={ExportModelEnum.Users}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default UsersRoute;
