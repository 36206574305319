import { useState } from 'react';

import { DeleteUserRequest } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import { usersApi } from '../../../../services/api';

export const useDeleteUser = () => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const deleteUser = async ({ environmentId, userId }: DeleteUserRequest) => {
    if (!confirm) return;
    if (!environmentId) {
      logger.error('No active environment found');
      return;
    }

    try {
      setLoading(true);
      await usersApi.deleteUser({ environmentId, userId });
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const toggleConfirm = () => setConfirm(!confirm);

  return { confirm, deleteUser, loading, toggleConfirm };
};
