/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    Gate,
    GateFromJSON,
    GateToJSON,
    GateCreateRequest,
    GateCreateRequestFromJSON,
    GateCreateRequestToJSON,
    GateUpdateRequest,
    GateUpdateRequestFromJSON,
    GateUpdateRequestToJSON,
    GatesResponse,
    GatesResponseFromJSON,
    GatesResponseToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateGateRequest {
    environmentId: string;
    gateCreateRequest: GateCreateRequest;
}

export interface DeleteGateRequest {
    environmentId: string;
    gateId: string;
}

export interface DisableGateRequest {
    environmentId: string;
    gateId: string;
}

export interface EnableGateRequest {
    environmentId: string;
    gateId: string;
}

export interface GetEnvironmentGatesRequest {
    environmentId: string;
}

export interface GetGateRequest {
    environmentId: string;
    gateId: string;
}

export interface UpdateGateRequest {
    environmentId: string;
    gateId: string;
    gateUpdateRequest: GateUpdateRequest;
}

/**
 * 
 */
export class GatesApi extends runtime.BaseAPI {

    /**
     * Creates a new gate for the project environment
     */
    async createGateRaw(requestParameters: CreateGateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Gate>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createGate.');
        }

        if (requestParameters.gateCreateRequest === null || requestParameters.gateCreateRequest === undefined) {
            throw new runtime.RequiredError('gateCreateRequest','Required parameter requestParameters.gateCreateRequest was null or undefined when calling createGate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/gates`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GateCreateRequestToJSON(requestParameters.gateCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GateFromJSON(jsonValue));
    }

    /**
     * Creates a new gate for the project environment
     */
    async createGate(requestParameters: CreateGateRequest, initOverrides?: RequestInit): Promise<Gate> {
        const response = await this.createGateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a gate
     */
    async deleteGateRaw(requestParameters: DeleteGateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteGate.');
        }

        if (requestParameters.gateId === null || requestParameters.gateId === undefined) {
            throw new runtime.RequiredError('gateId','Required parameter requestParameters.gateId was null or undefined when calling deleteGate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/gates/{gateId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"gateId"}}`, encodeURIComponent(String(requestParameters.gateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a gate
     */
    async deleteGate(requestParameters: DeleteGateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteGateRaw(requestParameters, initOverrides);
    }

    /**
     * Disable the gate for the environment
     */
    async disableGateRaw(requestParameters: DisableGateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Gate>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling disableGate.');
        }

        if (requestParameters.gateId === null || requestParameters.gateId === undefined) {
            throw new runtime.RequiredError('gateId','Required parameter requestParameters.gateId was null or undefined when calling disableGate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/gates/{gateId}/disable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"gateId"}}`, encodeURIComponent(String(requestParameters.gateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GateFromJSON(jsonValue));
    }

    /**
     * Disable the gate for the environment
     */
    async disableGate(requestParameters: DisableGateRequest, initOverrides?: RequestInit): Promise<Gate> {
        const response = await this.disableGateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable the gate for the environment
     */
    async enableGateRaw(requestParameters: EnableGateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Gate>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling enableGate.');
        }

        if (requestParameters.gateId === null || requestParameters.gateId === undefined) {
            throw new runtime.RequiredError('gateId','Required parameter requestParameters.gateId was null or undefined when calling enableGate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/gates/{gateId}/enable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"gateId"}}`, encodeURIComponent(String(requestParameters.gateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GateFromJSON(jsonValue));
    }

    /**
     * Enable the gate for the environment
     */
    async enableGate(requestParameters: EnableGateRequest, initOverrides?: RequestInit): Promise<Gate> {
        const response = await this.enableGateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the gates for an environment
     */
    async getEnvironmentGatesRaw(requestParameters: GetEnvironmentGatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GatesResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentGates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/gates`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatesResponseFromJSON(jsonValue));
    }

    /**
     * Get the gates for an environment
     */
    async getEnvironmentGates(requestParameters: GetEnvironmentGatesRequest, initOverrides?: RequestInit): Promise<GatesResponse> {
        const response = await this.getEnvironmentGatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a gate
     */
    async getGateRaw(requestParameters: GetGateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Gate>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getGate.');
        }

        if (requestParameters.gateId === null || requestParameters.gateId === undefined) {
            throw new runtime.RequiredError('gateId','Required parameter requestParameters.gateId was null or undefined when calling getGate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/gates/{gateId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"gateId"}}`, encodeURIComponent(String(requestParameters.gateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GateFromJSON(jsonValue));
    }

    /**
     * Gets a gate
     */
    async getGate(requestParameters: GetGateRequest, initOverrides?: RequestInit): Promise<Gate> {
        const response = await this.getGateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a gate
     */
    async updateGateRaw(requestParameters: UpdateGateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Gate>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateGate.');
        }

        if (requestParameters.gateId === null || requestParameters.gateId === undefined) {
            throw new runtime.RequiredError('gateId','Required parameter requestParameters.gateId was null or undefined when calling updateGate.');
        }

        if (requestParameters.gateUpdateRequest === null || requestParameters.gateUpdateRequest === undefined) {
            throw new runtime.RequiredError('gateUpdateRequest','Required parameter requestParameters.gateUpdateRequest was null or undefined when calling updateGate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/gates/{gateId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"gateId"}}`, encodeURIComponent(String(requestParameters.gateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GateUpdateRequestToJSON(requestParameters.gateUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GateFromJSON(jsonValue));
    }

    /**
     * Updates a gate
     */
    async updateGate(requestParameters: UpdateGateRequest, initOverrides?: RequestInit): Promise<Gate> {
        const response = await this.updateGateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
