import { CheckIcon, Icon, Typography } from '@dynamic-labs/northstar';
import { ExternalWalletFundingTokenRule } from '@dynamic-labs/sdk-api-core';

import styles from '../../FundingPage.module.scss';

export const ToggleButtons = ({
  value,
  onChangeIsRequired,
}: {
  onChangeIsRequired: (isRequired: boolean) => void;
  value: ExternalWalletFundingTokenRule;
}) => {
  const isRequired = value === ExternalWalletFundingTokenRule.Exact;

  return (
    <div className={styles.toggleContainer}>
      {['Required', 'Suggested'].map((option) => {
        const isSelected = isRequired
          ? option === 'Required'
          : option === 'Suggested';
        return (
          <button
            type='button'
            key={option}
            className={styles.toggleButton}
            onClick={() => onChangeIsRequired(option === 'Required')}
          >
            <span
              className={`${styles.toggleDot} ${
                isSelected
                  ? styles.toggleDotSelected
                  : styles.toggleDotUnselected
              }`}
            >
              {isSelected && (
                <Icon
                  icon={<CheckIcon />}
                  className={styles.toggleIcon}
                  color='white'
                />
              )}
            </span>
            <Typography variant='subtitle' weight='bold'>
              {option}
            </Typography>
          </button>
        );
      })}
    </div>
  );
};
