import { ChangeEvent, FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import {
  AuthStorageEnum,
  CustomHostnameStatusEnum,
  CustomHostnameUsageEnum,
} from '@dynamic-labs/sdk-api';
import { useGetCustomHostnames } from '@dynamic-labs/redcoast-query';

import { ROUTES } from '../../../../../app/components/Navigation/data';
import { useEnvironmentId } from '../../../../../app/routes/Configurations/Providers/hooks';
import {
  useModalHashLocation,
  ModalHashLocations,
} from '../../../../../app/routes/Configurations/utils';
import { AdminIcon } from '../../../../../icons';
import { useSettingsContext } from '../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';

import { CookieSidePage } from './CookieSidePage';
import styles from './CookieRow.module.scss';

export const CookieRow: FC = () => {
  const { t } = useTranslation();

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.CookieDomain,
  });

  const navigate = useNavigate();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const environmentId = useEnvironmentId();

  const { data: hostnames } = useGetCustomHostnames(
    { environmentId: environmentId || '' },
    {
      enabled: !!environmentId,
    },
  );

  const cookiesActiveHostname = useMemo(
    () =>
      hostnames?.customHostnames.find(
        (hostname) =>
          hostname.usage === CustomHostnameUsageEnum.Cookies &&
          hostname.status === CustomHostnameStatusEnum.Active,
      ),
    [hostnames],
  );

  const isCookieDomainEnabled =
    settings[activeEnvironmentType].security.auth?.storage[0] ===
    AuthStorageEnum.Cookie;
  const isCookieDomainSaved =
    settings[activeEnvironmentType].security.auth?.storage[0] ===
    initialSettings[activeEnvironmentType].security.auth?.storage[0];

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newStorage = e.target.checked ? ['cookie'] : ['localstorage'];
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        security: {
          ...settings[activeEnvironmentType].security,
          auth: {
            ...settings[activeEnvironmentType].security.auth,
            storage: newStorage,
          },
        },
      },
    });
  };

  return (
    <>
      <SingleToggleCard
        allowExpand={false}
        accordionKey='cookies-auth'
        title={t(
          'v2.page.security.cors_and_cookies_auth_row.cookie_row.toggle.title',
        )}
        description={t(
          'v2.page.security.cors_and_cookies_auth_row.cookie_row.toggle.description',
        )}
        inputProps={{
          checked: isCookieDomainEnabled,
          disabled: !cookiesActiveHostname,
          id: 'cookie-domain',
          isSaved: isCookieDomainSaved,
          onChange: handleToggleChange,
          type: 'toggle',
        }}
        onCardClick={
          isCookieDomainEnabled ? () => handleShowModal(true) : undefined
        }
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        actionLink={
          !cookiesActiveHostname
            ? {
                onClick: () => navigate(ROUTES.developerDomains),
                text: t(
                  'v2.page.security.cors_and_cookies_auth_row.cookie_row.toggle.action_link',
                ),
              }
            : undefined
        }
      />
      {cookiesActiveHostname?.hostname && (
        <CookieSidePage
          cookiesDomain={cookiesActiveHostname.hostname}
          open={showModal}
          onClose={() => handleShowModal(false)}
        />
      )}
    </>
  );
};
