import { Dispatch, FormEvent, SetStateAction } from 'react';

import { t } from 'i18next';

import { logger } from '../../../../services/logger';
import { useTokensContext } from '../../../../context/TokensContext';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { filterTokens } from '../helpers/filterTokens';
import { tokensApi } from '../../../../services/api';
import Button from '../../../../components/Button';
import Portal from '../../../../components/Portal';
import Header from '../../../../components/Portal/Header';

import styles from './DeleteTokenModal.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  tokenId: string;
};

export const DeleteTokenModal = ({ setShowModal, tokenId }: Props) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const { setTokens } = useTokensContext();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!activeEnvironment) return;

    try {
      await tokensApi.deleteTokenById({
        environmentId: activeEnvironment.id,
        tokenId,
      });

      setTokens(filterTokens(tokenId));
    } catch (error) {
      logger.error(error);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <Header
        handleClose={() => setShowModal(false)}
        headingText={t<string>('apis.delete_token_modal.title')}
      />
      <p className={styles.paragraph}>
        {t<string>('apis.delete_token_modal.content')}
      </p>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Button
          onClick={() => setShowModal(false)}
          variant='secondary'
          className={styles['cancel--button']}
          large
        >
          {t<string>('apis.delete_token_modal.cancel_button')}
        </Button>
        <Button
          type='submit'
          variant='secondary'
          className={styles['button--delete']}
          large
        >
          {t<string>('apis.delete_token_modal.delete_button')}
        </Button>
      </form>
    </Portal>
  );
};
