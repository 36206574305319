import { useState } from 'react';

import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import {
  VisitorFilterableFieldsEnum,
  ExportModelEnum,
} from '@dynamic-labs/sdk-api';

import ReactTable from '../../../components/ReactTable';
import { useDashboardContext } from '../../../context/DashboardContext';
import { useEnvironmentsContext } from '../../../context/EnvironmentsContext';
import { Pagination } from '../../../components/Pagination';
import { TableFilters } from '../components';
import { Alert } from '../../../components/Alert';
import { useSubscriptionContext } from '../../../context/SubscriptionContext';
import { UpsellBanner } from '../../../components/UpsellBanner';
import { useSubscriptionLock } from '../../../hooks/useSubscriptionLock';
import { ExportsModal } from '../AuthenticatedUsers/ExportsModal';
import Button from '../../../components/Button';

import styles from './visitors.module.css';
import {
  useFetchVisitors,
  useVisitorsColumns,
  useVisitorsSearchFilter,
} from './helpers';

const VISITORS_DOCS_URL =
  'https://docs.dynamic.xyz/branded-wallets/connected-vs-authenticated';

export const VisitorsRoute = () => {
  const { columns } = useVisitorsColumns();

  const { activeEnvironment } = useEnvironmentsContext();
  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner } = useSubscriptionLock();

  const {
    visitorsSearchFilter,
    setVisitorsSearchFilter,
    visitorsChainFilter,
    setVisitorsChainFilter,
  } = useVisitorsSearchFilter();

  const { activeProjectId } = useDashboardContext();
  const {
    offset,
    page,
    setPage,
    limit,
    total,
    setOffset,
    setVisitorSearchQuery,
    setSortBy,
    visitorsData,
  } = useFetchVisitors({
    activeProjectId,
    selectedEnvironmentId: activeEnvironment?.id,
    visitorsChainFilter,
    visitorsSearchFilter,
  });

  const filters = Object.values(VisitorFilterableFieldsEnum);

  const [showExportsModal, setShowExportsModal] = useState(false);

  return (
    <div className={styles.container}>
      {shouldShowTrialBanner && (
        <UpsellBanner daysLeft={subscription?.trialDaysLeft} className='mb-4' />
      )}
      <Alert className={styles.alert} severity='info'>
        <Trans i18nKey='visitors_management_table.warning'>
          The table below reflects the users who only connected (visitors), but
          did not sign their wallets. For more information, see the docs
          <a href={VISITORS_DOCS_URL} className={styles['alert--link']}>
            here
          </a>
          .
        </Trans>
      </Alert>
      <div className={styles['filters-section']}>
        <TableFilters
          filters={filters}
          onSearchQuery={setVisitorSearchQuery}
          searchFilter={visitorsSearchFilter}
          setSearchFilter={setVisitorsSearchFilter}
          chainFilter={visitorsChainFilter}
          setChainFilter={setVisitorsChainFilter}
        />
        <div className='flex items-center gap-4'>
          <Button onClick={() => setShowExportsModal(true)}>
            {t<string>('data_export.export_data')}
          </Button>
        </div>
      </div>

      <div className={styles.table__container}>
        <div data-testid='user_management'>
          <ReactTable
            columns={columns}
            data={visitorsData}
            withSortParams={{ onSort: (sortParam) => setSortBy(sortParam) }}
          />
        </div>
      </div>

      {total > 0 && (
        <Pagination
          total={total}
          forcePage={page}
          onPageChange={({ selected }) => {
            setOffset(selected * limit);
            setPage(selected);
          }}
          limit={limit}
          currentPageTotal={visitorsData?.length}
          offset={offset}
        />
      )}

      <AnimatePresence>
        {showExportsModal && (
          <ExportsModal
            setShowExportsModal={setShowExportsModal}
            model={ExportModelEnum.Visits}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
