import { useMemo, useState } from 'react';

import { Input, Typography } from '@dynamic-labs/northstar';
import {
  ChainEnum,
  ExternalWalletFundingDefaultSettingsToken,
  ExternalWalletFundingTokenRule,
} from '@dynamic-labs/sdk-api-core';

import { DropdownField } from '../../../../../app/components/DropdownField';
import styles from '../../FundingPage.module.scss';

import { ToggleButtons } from './ToggleButtons';

export const ChainTokenConfiguration = ({
  chainName,
  initialValue,
  onChange,
  options,
  placeholder,
  displayName,
}: {
  chainName: ChainEnum;
  displayName?: string;
  initialValue?: ExternalWalletFundingDefaultSettingsToken;
  onChange: (
    chainName: ChainEnum,
    value: ExternalWalletFundingDefaultSettingsToken,
  ) => void;
  options: {
    label: string;
    value: string;
  }[];
  placeholder: string;
}) => {
  const [otherValue, setOtherValue] = useState(
    initialValue?.value !== 'OTHER' ? initialValue?.value : '',
  );

  const selectedOption = useMemo(() => {
    if (
      initialValue?.value &&
      !options.find((option) => option.value === initialValue?.value)
    ) {
      return 'OTHER';
    }

    return initialValue?.value;
  }, [initialValue?.value, options]);

  const handleChange = (value?: string) => {
    onChange(chainName, {
      ...initialValue,
      value,
    });
  };

  return (
    <div key={chainName} className={styles.chainConfig}>
      <div className={styles.chainHeader}>
        <Typography className={styles.chainName} variant='subtitle'>
          {displayName || chainName}
        </Typography>
      </div>
      <div className={styles.dropdownContainer}>
        <DropdownField
          className={styles.dropdown}
          onSelect={(value) =>
            onChange(chainName, {
              ...initialValue,
              value,
            })
          }
          value={selectedOption}
          placeholder={placeholder}
          testId={`${chainName}-external-wallets-currency-dropdown`}
          options={options}
        />
      </div>
      <div className={styles.dropdownContainer}>
        <ToggleButtons
          value={initialValue?.rule ?? ('' as any)}
          onChangeIsRequired={(isRequired) =>
            onChange(chainName, {
              ...initialValue,
              rule: isRequired
                ? ExternalWalletFundingTokenRule.Exact
                : ExternalWalletFundingTokenRule.Recommended,
            })
          }
        />
      </div>
      {selectedOption === 'OTHER' && (
        <div className={styles.otherInput}>
          <Input
            name='otherValue'
            label='Token'
            placeholder='Name of token'
            value={otherValue}
            onChange={(value) => setOtherValue(value.target.value)}
            onBlur={() => handleChange(otherValue)}
          />
        </div>
      )}
    </div>
  );
};
