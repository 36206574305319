import { Provider, ProviderUpdateRequest } from '@dynamic-labs/sdk-api';

export const createProviderUpdateRequest = (
  savedProvider: Provider,
  provider: Provider,
): ProviderUpdateRequest => {
  const didClientSecretChange =
    savedProvider.clientSecret !== provider.clientSecret;

  return {
    accountSid: provider.accountSid,
    appleKeyId: provider.appleKeyId,
    appleTeamId: provider.appleTeamId,
    clientId: provider.clientId,
    defaultChain: provider.defaultChain,
    ecdsaProviderType: provider.ecdsaProviderType,
    enableEIP7702: provider.enableEIP7702,
    enableKernelV3Migration: provider.enableKernelV3Migration,
    enabledCountries: provider.enabledCountries,
    entryPointVersion: provider.entryPointVersion,
    kernelVersion: provider.kernelVersion,
    keyExportUrl: provider.keyExportUrl,
    multichainAccountAbstractionProviders:
      provider.multichainAccountAbstractionProviders,
    providerProjectId: provider.providerProjectId,
    scopes: provider.scopes,
    shopifyStore: provider.shopifyStore,
    twilioNumber: provider.twilioNumber,
    ...(didClientSecretChange ? { clientSecret: provider.clientSecret } : {}),
  };
};
