/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import {
    AssetExposureSpenderToInfo,
    AssetExposureSpenderToInfoFromJSON,
    AssetExposureSpenderToInfoFromJSONTyped,
    AssetExposureSpenderToInfoToJSON,
} from './AssetExposureSpenderToInfo';

/**
 * 
 * @export
 * @interface AssetExposure
 */
export interface AssetExposure {
    /**
     * 
     * @type {Asset}
     * @memberof AssetExposure
     */
    asset: Asset;
    /**
     * 
     * @type {{ [key: string]: AssetExposureSpenderToInfo; }}
     * @memberof AssetExposure
     */
    spenderToInfo: { [key: string]: AssetExposureSpenderToInfo; };
}

export function AssetExposureFromJSON(json: any): AssetExposure {
    return AssetExposureFromJSONTyped(json, false);
}

export function AssetExposureFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetExposure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': AssetFromJSON(json['asset']),
        'spenderToInfo': (mapValues(json['spenderToInfo'], AssetExposureSpenderToInfoFromJSON)),
    };
}

export function AssetExposureToJSON(value?: AssetExposure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': AssetToJSON(value.asset),
        'spenderToInfo': (mapValues(value.spenderToInfo, AssetExposureSpenderToInfoToJSON)),
    };
}

