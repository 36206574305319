/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlockaidValidation
 */
export interface BlockaidValidation {
    /**
     * 
     * @type {string}
     * @memberof BlockaidValidation
     */
    result: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlockaidValidation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockaidValidation
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof BlockaidValidation
     */
    classification?: string;
}

export function BlockaidValidationFromJSON(json: any): BlockaidValidation {
    return BlockaidValidationFromJSONTyped(json, false);
}

export function BlockaidValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockaidValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': json['result'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'reason': json['reason'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
    };
}

export function BlockaidValidationToJSON(value?: BlockaidValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
        'description': value.description,
        'reason': value.reason,
        'classification': value.classification,
    };
}

